<!-- Digitalización Facturas -->

<template>
  <div class="frasDigi" v-if="loaded">   
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div class="columna">
                 
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div> 

            <v-sheet :elevation="4">
              <div class="contenedor">    
          
                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>                
                </div>
          

                <!-- Controles del Mto -->                
                <div class="columna" style="width:550px;padding-top:10px">
                  <div class="conflex">
                    <uiDate
                      style="width:50%" 
                      v-model="ct.digf_fh[2]"
                      :label="ct.digf_fh[1]"
                      type="datetime-local"
                      labelSide
                      readonly>                      
                    </uiDate>

                    <uiText
                      style="width:45%;margin-left:5px"
                      styleInput="color:red" 
                      v-model="txtEstado"
                      label="Estado"
                      labelSide
                      readonly>
                    </uiText>
                  </div>

                  <div class="pt-2" style="display:flex">
                    <uiText                      
                      v-model="ct.digf_usu[2]"
                      :label="ct.digf_usu[1]"
                      readonly>      
                    </uiText>
                    
                    <v-select
                      style="margin-left:5px"
                      v-bind="$select"
                      v-model="ct.digf_rec[2]"
                      :label="ct.digf_rec[1]"            
                      :items="itemsRecepcion" 
                      item-value="name"
                      item-text="name"
                      :disabled="digitalizada">
                    </v-select>
                  </div>
                
                  <v-textarea
                    v-bind="$textarea"
                    v-model="ct.digf_obs[2]"
                    :label="ct.digf_obs[1]"     
                    rows='2'
                    no-resize
                    :disabled="digitalizada">
                  </v-textarea>
                </div>
              </div>  
            </v-sheet> 
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader"; 
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
        
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {
      tipo: { type: [String, Number], default: "1" }, 
    },

    data() {
      return {
        stIni: {
          api: "frasDigiM",
          name:"frasDigiM",
          titulo:"Digitalización de Facturas",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },     
       
        itemsRecepcion: []
      };
    },
   

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'guardar', btn: "guardar" }          
        ];
        
              
        // items adicionales
        this.itemsRecepcion= [
          { id:"0", name:"Ordinario"},
          { id:"1", name:"Certificado"},
          { id:"2", name:"Mensajeria"},
          { id:"3", name:"Otros"},
          { id:"4", name:"Firma Digital"},
        ];
      },


      iniDataApi() { 
        this.apiArgs.sch= null;
        this.apiArgs.digi= ["", 'digiFact', { id:0, tip:this.tipo }, "facturas"];
      },
  

      // al terminar de cargar el registro, si la factura aun no ha sido
      // digitalizada, inicializo datos schema
      // deshabilita botón guardar si la factura ya se encuentra digitalizada
      verFin() {        
        this.btnSet(this.btExtCfg, "guardar", { disabled: this.digitalizada });

        if (this.digitalizada) return;      
        this.$set(this.ct.digf_fh, 2, this.actualDateTime());
        this.$set(this.ct.digf_usu, 2, this.usu);
      },


      // guardo datos de digitalización
      async guardar() {  
        let ctrls= this.ctrl2record(this.ct, {}, false);
        let param = { apiArg: this.apiArgs.digi, args: { ...ctrls, ...{ id:this.record.id }}};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }  

        this.$root.$alert.open('Datos guardados correctamente', 'success', 1800);
        
        // recargo datos finder facturas
        this.$emit('refresh');

        // emito evento para cerrar la ventana
        this.$emit('onEvent', { accion:6 });
      }
    },


    computed: {

      // devuelve schema particular
      sch() {
        return {
          digf_fh: [ 'digf_fh', 'Fecha', "" ],
          digf_usu: [ 'digf_usu', 'Usuario', "" ],
          digf_rec: [ 'digf_rec', 'Recepción', "Ordinario" ],
          digf_obs: [ 'digf_obs', 'Observaciones', "" ]
        }        
      },


      // devuelve si la fra está digitalizada o no
      digitalizada() {
        return new Date(this.syncRecord.digf_fh).getTime()? true : false
      },


      //
      txtEstado() {
        return this.digitalizada? 'Digitalizada' : '';
      }

    },

  };
</script>
